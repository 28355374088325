import Alpine from 'alpinejs';
window.Alpine = Alpine

// GLOBAL CONFIGURATION
const $config = (window.GRAVY_Rover_Config ?? []);

// GLOBAL JQUERY DEFINITION
const $ = jQuery;

// Alpine data
Alpine.data('gravy_rover_data', () => ({
	$roverPage: $('#rover-page'),
	$tabsContainer: $('#rover-page .tabs-nav-container:not(.fake-screen)'),
	$tabsNav: $('#rover-page .tabs-nav-container:not(.fake-screen) .tabs-nav'),
	$tabsCarousel: $('#rover-page .tabs-carousel'),
	$postsList: $('#rover-page .posts-list'),
	$endOfList: $('#rover-page .end-of-list'),
	tabsHasScroll: false,
	tabs: $config.Tabs,
	roverId: $('#rover-page').data('rover-id'),
	currentTabIndex: parseInt($('#rover-page').data('current-tab-index')),
	isLoading: true,
	isAppending: false,
	hasPage: true,
	page: 1,
	isClickedOnLoadMore: false,

	// DO A TAB ACTION
	doTabAction(tabIndex) {
		const $this = this;

		// Set the current tab index
		this.$data.currentTabIndex = tabIndex;
	},

	// SCROLLS
	scrollLeft() {
		const $this = this;
		const $data = this.$data;
		const $nav = $data.$tabsNav;

		$nav.stop().animate({ scrollLeft: Math.max(0, $nav.scrollLeft() - $(window).width() / 2) }, 750, 'swing')
	},
	scrollRight() {
		const $this = this;
		const $data = this.$data;
		const $nav = $data.$tabsNav;

		$nav.stop().animate({ scrollLeft: Math.min($nav.prop('scrollWidth'), $nav.scrollLeft() + $(window).width() / 2) }, 750, 'swing')
	},

	// Follow user by link
	followUserByLink(e) {
		if (!e || !e.target) {
			return;
		}

		// Stop the default
		e.preventDefault();

		// Get the href
		const follow_url = e.target.href ?? (e.target.dataset.follow ?? '')
		const followed_user_id = e.target.dataset.followFor

		// No follow url?
		if (!follow_url || !followed_user_id) {
			return;
		}

		// Make a request to the url
		$.get(`${follow_url}`);

		// Replace all the buttons with 'Following' status
		$(`[data-follow-for="${followed_user_id}"]`).hide();
		$(`[data-follow-status-for="${followed_user_id}"]`).show();
	},

	// LOAD MORE POSTS
	loadNextPage(trigger) {
		const $this = this;
		const $data = this.$data;
		const $nav = $data.$tabsNav;

		// Is appending or loading or no next page
		if ($data.isAppending || $data.isLoading || !$data.hasPage) {
			return;
		}

		// If the trigger is the button
		if (trigger === 'load-more') {
			$data.isClickedOnLoadMore = true;
		}

		let page = getPaged();
		$data.isAppending = true;

		// Retrieve the parsed content
		$.post($config.Endpoints.RetrieveTabContent, { tab_index: $data.currentTabIndex, 'g-page': page + 1 }, function (response) {
			$data.isAppending = false;
			let html = response.data.html;
			if (response.success && html.length > 0) {
				setPaged(page + 1)
				$data.page = page + 1;
				$data.$postsList.append(response.data.html);

				// Re-render ads
				if (window.reRenderAdsByGoogle) { reRenderAdsByGoogle(); }

				// Rover changes
				if (window.eventOnRoverUpdate) { eventOnRoverUpdate(); }

				// Rover changes
				if (window.eventOnRoverPageUpdate) { eventOnRoverPageUpdate(); }

				watchViewportPage();
			} else {
				// Remove the page loaders
				$data.hasPage = false;
			}
		});
	},

	// INIT THE APP
	init() {
		const $this = this;
		const $data = this.$data;
		const $nav = $data.$tabsNav;
		const $carousel = $data.$tabsCarousel;
		const $rover = $data.$roverPage;

		$(document).ready(() => {
			$data.isLoading = false;
			$data.isAppending = false;

			watchViewportPage();

			// Show the rover-page
			$rover.show();

			// Show the tabs
			$data.$tabsContainer.show();

			// Hide the fake screen
			$rover.find('.fake-screen').remove();

			const checkNavScrollEvents = () => {
				// Is the scrollbar visible?
				if (xScrollbarVisible($nav)) {
					if ($nav.scrollLeft() > 10) {
						$nav.addClass('--with-left-arrow')
					} else {
						$nav.removeClass('--with-left-arrow')
					}

					if (($nav.scrollLeft() + $nav.width()) < ($nav.prop('scrollWidth') - 10)) {
						$nav.addClass('--with-right-arrow')
					} else {
						$nav.removeClass('--with-right-arrow')
					}

					$nav.addClass('--with-scroll')
					$data.tabsHasScroll = true;
				}
			}

			checkNavScrollEvents()
			$nav.on('scroll', checkNavScrollEvents);

			// Infinite scroll on document
			$(window).scroll(function () {
				// Is the button clicked at least once?
				if (!$data.isClickedOnLoadMore) {
					return;
				}

				// End of the list is visible reached?
				let bounding = $data.$endOfList.get(0).getBoundingClientRect();
				let inViewport = bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
				if (inViewport) {
					$data.loadNextPage()
				}
			});


			// Drag to scroll
			let navPosition = { top: 0, left: 0, x: 0, y: 0 };

			const mouseMoveHandler = function (e) {
				$nav.css('pointer-events', 'none');
				$nav.addClass('dragging');

				// How far the mouse has been moved
				const dx = e.clientX - navPosition.x;
				const dy = e.clientY - navPosition.y;

				// Scroll the element
				$nav.scrollTop(navPosition.top - dy);
				$nav.scrollLeft(navPosition.left - dx);
			};

			const mouseUpHandler = function () {
				$(document).off('pointermove', mouseMoveHandler);
				$(document).off('pointerup', mouseUpHandler);

				$nav.css('cursor', 'grab');
				$nav.css('user-select', '');
				$nav.css('pointer-events', '');
				$nav.removeClass('dragging');
			};

			const mouseDownHandler = function (e) {
				navPosition = {
					// The current scroll
					left: $nav.scrollLeft(),
					top: $nav.scrollTop(),
					// Get the current mouse position
					x: e.clientX,
					y: e.clientY,
				};

				$nav.css('user-select', 'none')

				$(document).on('pointermove', mouseMoveHandler);
				$(document).on('pointerup', mouseUpHandler);
			};

			$nav.on('pointerdown', mouseDownHandler)

		});

		$this.$watch('isLoading', (value) => {
			if (value) {
				$data.$postsList.hide();
			} else {
				$data.$postsList.show();
			}
		});

		// WATCH CHANGES ON CURRENT TAB INDEX
		$this.$watch('currentTabIndex', (tabIndex) => {
			const $tab = $data.tabs[tabIndex];

			// Reset the pagination data
			setPaged(1)
			$data.hasPage = true;
			$data.page = 1

			// No tab?
			if (!$tab) {
				$data.currentTabIndex = 0;
				return;
			} else {
				// Scroll into view the div
				// $data.$tabsNav.find('.tab').get(tabIndex).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
			}

			// Post query?
			if (
				$tab.type == 'post_tag' || $tab.type == 'category' || $tab.type == 'user' || $tab.type == 'page'
				|| $tab.type == 'featured-latest' || $tab.type == 'featured-random'
				|| $tab.type == 'rss-latest' || $tab.type == 'rss-random'
				|| $tab.type == 'featured-or-rss-latest' || $tab.type == 'featured-or-rss-random'
				|| $tab.type == 'random' || $tab.type == 'random-high-scored-60'
				|| $tab.type == 'high-scored'
				|| $tab.type == 'following'
			) {
				$data.isLoading = true

				// Push state
				window.history.pushState({ tabIndex }, '', $tab.link);

				// Retrieve the parsed content
				$.post($config.Endpoints.RetrieveTabContent, { rover_id: $data.roverId, tab_index: $data.currentTabIndex }, function (response) {
					$data.isLoading = false;

					if (response.success) {
						$data.$postsList.html(response.data.html);

						// Adsense fix
						if (window.reRenderAdsByGoogle) { reRenderAdsByGoogle(); }

						// Rover changes
						if (window.eventOnRoverUpdate) { eventOnRoverUpdate(); }

						// Rover changes
						if (window.eventOnRoverTabChange) { eventOnRoverTabChange(); }

						// Reset click data
						$data.isClickedOnLoadMore = false;
					}
				});
			}
		})
	},
}))

// ALPINE STARTS
Alpine.start();

/**
 * Is the scrollbar visible of an element?
 * @returns bool
 */
function xScrollbarVisible(element) {
	return $(element)[0].scrollWidth > $(element)[0].clientWidth;
}

function hasScroll(el, direction) {
	direction = (direction === 'vertical') ? 'scrollTop' : 'scrollLeft';
	var result = !!el[direction];

	if (!result) {
		el[direction] = 1;
		result = !!el[direction];
		el[direction] = 0;
	}
	return result;
}

// Read a page's GET URL variables and return them as an associative array.
function getUrlVars() {
	if (window.location.href.indexOf('?') <= 0) {
		return [];
	}

	var vars = [], hash;
	var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for (var i = 0; i < hashes.length; i++) {
		hash = hashes[i].split('=');
		vars.push(hash[0]);
		vars[hash[0]] = hash[1];
	}
	return vars;
}

/**
 * Current page data holders
 */
let paged = 1;
function getPaged() {
	return paged;
	return Math.max(getUrlVars()['g-page'] ?? 0, 1);
}

function setPaged(page) {
	paged = page;
	return;
	if (getPaged() != page) {
		history.replaceState({}, '', '?g-page=' + page);
	}
}

/**
 * Viewport changes for scroll shadows
 */
function watchViewportPage() {
	$(window).off("load scroll resize", detectViewportPage);
	$(window).on("load scroll resize", detectViewportPage);
}
function detectViewportPage() {
	var scroll = $(window).scrollTop();
	var elements = $("[data-page-identifier]"); // VERY VERY bad performance tho, watch out!
	var el;
	for (var i = 0; i < elements.length; i++) {
		el = $(elements[i]);
		if (el.offset().top >= scroll && el.is(':visible')) {
			// "el" is the first visible element here!
			// Do something fancy with it

			setPaged(parseInt(el.data('page-identifier')))

			// Quit the loop
			break;
		}
	}
}